<template>
  <div class="epitelium-game">
    <div class="epitelium-game__block">
      <div class="epitelium-game__area">
        <div class="epitelium-game__area-container" v-if="gameStarted">
          <img :src="require('@/assets/img/epitelium/game/back.jpg')" />
          <div
            class="epitelium-game__target"
            :style="`width: ${target.width}; height: ${target.height}; top: ${target.top}; left: ${target.left}; ${target.style}`"
            :data-target="target.target"
            v-for="(target, ind) in Targets"
            :key="ind"
          >
            <img
              v-show="target.done"
              :src="target.img"
              class="epitelium-game__target-img"
            />
          </div>
        </div>
         <div class="epitelium-game__area-container" v-else>
          <img :src="require('@/assets/img/epitelium/game/default-back.jpg')" />
         </div>
      </div>
      <div class="epitelium-game__elements-container">
        <div class="epitelium-game__elements" v-if="gameStarted">
          <div
            class="epitelium-game__element-container"
            v-for="(el, ind) in Elements"
            :key="ind"
          >
            <div
              class="epitelium-game__element"
              v-if="!el.done"
              @mousedown="mDownEl($event, el)"
              @touchstart="mDownEl($event, el)"
            >
              <img :src="el.image" class="epitelium-game__element-img" />
              <div
                class="epitelium-game__element-title"
                v-html="el.title"
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "EpiteliumGame",
  props: {
    gameStarted: Boolean
  },
  data: () => ({
    grabbedEl: null,
    Targets: [
      {
        width: "100%",
        height: "50px",
        target: "elem1",
        left: "0%",
        top: "94%",
        img: require("@/assets/img/epitelium/game/elements/1_done.png"),
        done: false,
      },
      {
        width: "23%",
        height: "13%",
        top: "68%",
        left: "-1%",
        target: "elem2",
        img: require("@/assets/img/epitelium/game/elements/2_done.png"),
        done: false,
      },
      {
        width: "33%",
        height: "18%",
        top: "40.3%",
        left: "7%",
        target: "elem3",
        img: require("@/assets/img/epitelium/game/elements/3_done.png"),
        done: false,
      },
      {
        width: "23%",
        height: "15%",
        top: "65.3%",
        left: "42%",
        target: "elem4",
        img: require("@/assets/img/epitelium/game/elements/4_done.png"),
        done: false,
      },
      {
        width: "19%",
        height: "12%",
        top: "44.3%",
        left: "43%",
        target: "elem5",
        img: require("@/assets/img/epitelium/game/elements/6_done.png"),
        done: false,
      },
      {
        width: "19%",
        height: "12%",
        top: "39.3%",
        left: "73%",
        target: "elem6",
        img: require("@/assets/img/epitelium/game/elements/5_done.png"),
        done: false,
      },
      {
        width: "19%",
        height: "12%",
        top: "82.3%",
        left: "23%",
        target: "elem7",
        img: require("@/assets/img/epitelium/game/elements/7_done.png"),
        done: false,
      },
      {
        width: "13%",
        height: "13%",
        top: "57.3%",
        left: "20%",
        target: "elem8",
        img: require("@/assets/img/epitelium/game/elements/10_done.png"),
        done: false,
      },
      {
        width: "13%",
        height: "13%",
        top: "57.7%",
        left: "4.4%",
        target: "elem9",
        img: require("@/assets/img/epitelium/game/elements/9_done.png"),
        done: false,
      },
      {
        width: "24%",
        height: "13%",
        top: "78.7%",
        left: "1.4%",
        target: "elem10",
        img: require("@/assets/img/epitelium/game/elements/8_done.png"),
        done: false,
      },
      {
        width: "24%",
        height: "13%",
        top: "57.7%",
        left: "67.4%",
        target: "elem11",
        img: require("@/assets/img/epitelium/game/elements/8_2_done.png"),
        done: false,
      },
      {
        width: "67%",
        height: "14%",
        top: "17.7%",
        left: "18.4%",
        target: "elem12",
        img: require("@/assets/img/epitelium/game/elements/11_done.png"),
        done: false,
      },
      {
        width: "13%",
        height: "14%",
        top: "70.7%",
        left: "74.4%",
        target: "elem13",
        style: "padding: 0 3%;",
        img: require("@/assets/img/epitelium/game/elements/12_done.png"),
        done: false,
      },
      {
        width: "10%",
        height: "11%",
        top: "82.7%",
        left: "85.4%",
        target: "elem14",
        img: require("@/assets/img/epitelium/game/elements/13_done.png"),
        done: false,
      },
    ],
  }),
  computed: {
    Elements() {
      return [
        {
          title: "Эпителиальные цитокины",
          image: require("@/assets/img/epitelium/game/elements/11.png"),
          target: "elem12",
        },
        {
          title: "B-клетка",
          image: require("@/assets/img/epitelium/game/elements/2.png"),
          target: "elem2",
        },
        {
          title: "Дендритная клетка",
          image: require("@/assets/img/epitelium/game/elements/3.png"),
          target: "elem3",
        },
        {
          title: "Эозинофил",
          image: require("@/assets/img/epitelium/game/elements/4.png"),
          target: "elem4",
        },
        {
          title: "ILC2",
          image: require("@/assets/img/epitelium/game/elements/6.png"),
          target: "elem5",
        },
        {
          title: "Фибробласты <br>Коллаген",
          image: require("@/assets/img/epitelium/game/elements/5.png"),
          target: "elem6",
        },
        {
          title: "Воспалительные медиаторы",
          image: require("@/assets/img/epitelium/game/elements/7.png"),
          target: "elem7",
        },
        {
          title: "Th2",
          image: require("@/assets/img/epitelium/game/elements/10.png"),
          target: "elem8",
        },
        {
          title: "Th0",
          image: require("@/assets/img/epitelium/game/elements/9.png"),
          target: "elem9",
        },
        {
          title: "Тучная клетка",
          image: require("@/assets/img/epitelium/game/elements/8.png"),
          target: "elem10",
        },
        {
          title: "Тучная клетка",
          image: require("@/assets/img/epitelium/game/elements/8.png"),
          target: "elem11",
        },
        {
          title: "TLSP",
          image: require("@/assets/img/epitelium/game/elements/12.png"),
          target: "elem13",
        },
        {
          title: "TLSP",
          image: require("@/assets/img/epitelium/game/elements/12.png"),
          target: "elem14",
        },
         {
          title: "Гладкая мускулатура дыхательных путей",
          image: require("@/assets/img/epitelium/game/elements/1.png"),
          target: "elem1",
        },
      ];
    },
  },
  methods: {
    mDownEl(e, elem) {
      const vm = this;
      const el = e.target;
      const vueEl = elem;
      vm.grabbedEl = el;
      el.ondragstart = function () {
        return false;
      };
      const parent = el.closest(".epitelium-game__element");
      const parentContainer = el.closest(".epitelium-game__element-container");
      const elementsContainer = el.closest(".epitelium-game__elements");
      document.querySelector("body").style.overflowY = "hidden";
      document.querySelector("html").style.overflowY = "hidden";
      document.querySelector("body").style.marginRight = "";

      function moveEl(e) {
        elementsContainer.classList.add("epitelium-game__elements_hide");
        const parentRect = parentContainer.getBoundingClientRect();
        const top = e.pageY || e.targetTouches[0].pageY;
        const topScroll = e.clientY || e.targetTouches[0].clientY;
        const left = e.pageX || e.targetTouches[0].pageX;
        if (topScroll > window.innerHeight - 100) {
          window.scrollTo({
            top: window.scrollY + window.innerHeight / 4,
            behavior: "smooth",
          });
        }
        if (topScroll < 100) {
          window.scrollTo({
            top: window.scrollY - window.innerHeight / 4,
            behavior: "smooth",
          });
        }
        parent.style.transition = "";
        parent.style.zIndex = 999;
        parent.style.transform = `translate(${
          left - parentRect.left - parent.offsetWidth / 2
        }px, ${
          top - (parentRect.top + window.scrollY) - parent.offsetHeight / 2
        }px)`;
      }
      document.onmousemove = function (e) {
        moveEl(e);
      };
      document.ontouchmove = function (e) {
        moveEl(e);
      };
      function returnBack(e) {
        elementsContainer.classList.remove("epitelium-game__elements_hide");
        let target =
          e.type === "touchend"
            ? document
                .elementFromPoint(
                  event.changedTouches[0].clientX,
                  event.changedTouches[0].clientY
                )
                .getAttribute("data-target")
            : e.target.getAttribute("data-target");
        if (vueEl.target === target) {
          let targ = vm.Targets.find((el) => el.target === target);
          targ.done = true;
          vueEl.done = true;
          parentContainer.remove();
          let isDone = vm.Targets.filter((el) => !el.done)
          vm.$emit('isDone', !!!isDone.length)
        }
        document.onmousemove = null;
        document.ontouchmove = null;
        el.onmouseup = null;
        el.ontouchend = null;
        parent.style.transition = "0.7s";
        parent.style.zIndex = "";
        parent.style.transform = "translate(0, 0)";
        vm.grabbedEl = null;
        document.querySelector("body").style.overflowY = "";
        document.querySelector("html").style.overflowY = "";
        document.querySelector("body").style.marginRight =
          "-" + vm.getScrollBarSize()[0] + "px";
      }
      el.onmouseup = function (e) {
        returnBack(e);
      };
      document.onmouseup = function (e) {
        if (vm.grabbedEl) {
          returnBack(e);
        }
      };
      document.ontouchend = function (e) {
        if (vm.grabbedEl) {
          returnBack(e);
        }
      };
    },
    getScrollBarSize() {
      let el = window.document.createElement("textarea"),
        style = {
          visibility: "hidden",
          position: "absolute",
          zIndex: "-2147483647",
          top: "-1000px",
          left: "-1000px",
          width: "1000px",
          height: "1000px",
          overflow: "scroll",
          margin: "0",
          border: "0",
          padding: "0",
        },
        support = el.clientWidth !== undefined && el.offsetWidth !== undefined;

      for (let key in style) {
        if (style.hasOwnProperty(key)) {
          el.style[key] = style[key];
        }
      }

      let size = null;
      if (support && window.document.body) {
        window.document.body.appendChild(el);
        size = [
          el.offsetWidth - el.clientWidth,
          el.offsetHeight - el.clientHeight,
        ];
        window.document.body.removeChild(el);
      }
      return size;
    },
  },
};
</script>

<style lang="scss" scoped>
.epitelium-game {
  &__block {
    position: relative;
    overflow: hidden;
    display: grid;
    grid-template-columns: 1fr 20%;
    padding: 0 16%;

    @media screen and (max-width: 1220px) {
      grid-template-columns: 1fr 21%;
    }

    @media screen and (max-width: 767px) {
      grid-template-columns: 1fr;
    }
  }

  &__area {
    width: 100%;

    &-container {
      width: 100%;
      position: relative;
      height: fit-content;

      & > img {
        width: 100%;
      }
    }
  }

  &__target {
    position: absolute;
    // background-color: rgba(255, 255, 255, 0.2);
    user-select: none;
    z-index: 1000;

    &-img {
      width: 100%;
    }
  }

  &__elements {
    // position: sticky;
    // top: 126px;
    padding: 40px 16px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 16px;
    // flex-direction: column;
    // align-items: center;
    // justify-content: flex-start;

    @media screen and (max-width: 767px) {
      background-color: rgba(0, 0, 0, 0.9);
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;
      overflow-y: auto;
      width: 40%;
      transition: 0.7s;
      z-index: 99999999;

      &_hide {
        overflow: visible;
        transform: translateX(100%);
      }
    }
  }

  &__element-container {
    margin-bottom: 16px;
  }

  &__element {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    // margin-bottom: 16px;
    cursor: pointer;
    user-select: none;

    &:last-child {
      margin-bottom: 0;
    }

    &-img {
      width: 50%;
      object-fit: contain;
      object-position: center;
      margin-bottom: 10px;
    }

    &-title {
      font-family: "Roboto", sans-serif;
      font-size: 12px;
      font-weight: 400;
      line-height: 14px;
      color: #fff;
      text-align: center;
    }
  }
}
</style>